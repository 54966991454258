import React, { useState, useEffect } from 'react'
import useAnalyticsEventTracker from '../../GoogleAnalytics'
import { TailSpin } from 'react-loading-icons'
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faHackerrank, faLinkedin/*, faWhatsapp*/ } from '@fortawesome/free-brands-svg-icons'
const axios = require('axios')
const request = require('../../config/request')
const googleAnalytics = ((post) => {
  useAnalyticsEventTracker(post);
})

function Footer(){
  const [loadSocial, setLoadSocial] = useState(true)
  const [social, setSocial] = useState([])
  const id = 'footer'
  useEffect(() => {
    (async() => {
      let arrSocial = {data: []}
      if(loadSocial){
        //Social
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'social'}).then(
          response => {
            arrSocial.data.push(response.data.data)
          }
        )
        setSocial([...arrSocial.data[0]])
        setLoadSocial(false)
      }
      console.log('social')
      console.log(social)
    })()
  }, [loadSocial, setLoadSocial, social, setSocial])
  const footer = {
    id: id,
    w3containerw3tealw3centerw3margintop: {
      className: 'w3-container w3-teal w3-center w3-margin-top',
      w3small: {
        className: 'w3-small'
      }
    }
  }
  if(loadSocial){
    return(<TailSpin stroke="#264b87" direction='ltr' />)
  }
  return(
    <footer className={footer.w3containerw3tealw3centerw3margintop.className}>
      <p>Find me on social media.</p>
      {/*Start looping socials here*/}
      {social.length > 0 ? Object.keys(social).map(key =>
        <i className='w3-hover-opacity' style={{padding: '5px'}} key={key}>
          {social[key].fa_logo === 'faGithub' ? <Link href={social[key].url} target='_blank' rel='noreferrer' onClick={() => {
            let post = {
              category: 'social',
              action: 'click_social_' + social[key].platform_name,
              label: 'social_' + social[key].platform_name
            }
            console.log(post)
            googleAnalytics(post)
          }}><FontAwesomeIcon icon={faGithub} size='lg' /></Link> : <></>}
          {social[key].fa_logo === 'faHackerrank' ? <Link href={social[key].url} target='_blank' rel='noreferrer' onClick={() => {
            let post = {
              category: 'social',
              action: 'click_social_' + social[key].platform_name,
              label: 'social_' + social[key].platform_name
            }
            console.log(post)
            googleAnalytics(post)
          }}><FontAwesomeIcon icon={faHackerrank} size='lg' /></Link> : <></>}
          {social[key].fa_logo === 'faLinkedin' ? <Link href={social[key].url} target='_blank' rel='noreferrer' onClick={() => {
            let post = {
              category: 'social',
              action: 'click_social_' + social[key].platform_name,
              label: 'social_' + social[key].platform_name
            }
            console.log(post)
            googleAnalytics(post)
          }}><FontAwesomeIcon icon={faLinkedin} size='lg' /></Link> : <></>}
          {/*social[key].fa_logo === 'faWhatsapp' ? <Link href={social[key].url}><FontAwesomeIcon icon={faWhatsapp} size='lg' /></Link> : <></>*/}
        </i>
      ) : <></>}

      {/*End looping socials here*/}
      <p className={footer.w3containerw3tealw3centerw3margintop.w3small.className}>
        Template by <Link href='https://spaces.w3schools.com/' target='_blank' rel='noreferrer' onClick={() => {
          let post = {
            category: 'link',
            action: 'click_footer_link',
            label: 'footer_link'
          }
          console.log(post)
          googleAnalytics(post)
        }}>W3schools Spaces</Link>
      </p>
      {/*
      <a class="w3-button w3-round-xxlarge w3-small w3-light-grey w3-margin-bottom" href="https://www.w3schools.com/spaces" target="_blank">
        Start now
      </a>
      */}
      <br/>
      <br/>
    </footer>
  )
}

export default Footer
