import React, { useState } from 'react'
import useAnalyticsEventTracker from '../GoogleAnalytics'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
//import Stack from '@mui/material/Stack'
const axios = require('axios')
const request = require('../config/request')
const googleAnalytics = ((post) => {
  useAnalyticsEventTracker(post);
})

function Contact() {
  const id = 'contact'
  const [ loading, setLoading ] = useState(false)
  const [ notif, setNotif ] = useState('')
  const { register, handleSubmit, formState: { errors } } = useForm()
  const onSubmit = async (data) => {
    setLoading(true)
    let status
    /*
    console.log(data.fullName)
    console.log(data.email)
    console.log(data.message)
    */
    let postMessageOptions = {
      from: data.fullName + ' <' + data.email + '>',
      to: 'i.am.listyono@gmail.com',
      replyTo: data.email,
      subject: 'Mail from ' + data.fullName + ' <' + data.email + '>',
      text: data.message,
      message: data.message
    }
    console.log(postMessageOptions)
    await axios.post(request.api.url + '/v1/mail/send-mail', postMessageOptions).then(
      response => {
        console.log(response)
        status = response.status
        if(status === 200){
          setNotif('Message was sent successfully!')
        }
        else{
          setNotif('Message was not sent!')
        }
      }
    )
    let post = {
      category: 'button',
      action: 'send_email',
      label: 'send_email'

    }
    console.log(post)
    googleAnalytics(post)
    setLoading(false)
  }
  return (
    <div id={id}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '60ch', bgcolor: 'white', color: 'grey', fontWeight: 'bolder' }
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div><h4><FontAwesomeIcon icon={faEnvelope} /> Please share your thoughts!</h4></div>
        {/*Full name*/}
        <div><TextField error={errors.fullName ? true : false} id={errors.fullName ? "standard-error-helper-text" : "filled-required"} variant="filled" label={errors.fullName ? errors.fullName.message : "Full name"} InputLabelProps={{style: {fontWeight: 'bolder'}}} type="text" placeholder="Type your full name, max. 100 characters (required)" {...register("fullName", {required: {value: true, message: "Full name is required!"}, maxLength: {value: 100, message: "Max. length is 100 characters!"}})} /></div>
        {/*Email*/}
        <div><TextField className="contact-field" error={errors.email ? true : false} id={errors.email ? "standard-error-helper-text" : "filled-required"} variant="filled" label={errors.email ? errors.email.message : "Email"} InputLabelProps={{style: {fontWeight: 'bolder'}}} type="text" placeholder="Type your valid email (required)" {...register("email", {required: {value: true, message: "Email is required!"}, pattern: {value: /^\S+@\S+$/i, message: "Please use valid email!"}})} /></div>
        {/*Message*/}
        <div><TextField className="contact-field" error={errors.message ? true : false} id={errors.message ? "standard-error-helper-text" : "filled-textarea"} variant="filled" label={errors.message ? errors.message.message : "Message"} InputLabelProps={{style: {fontWeight: 'bolder'}}} multiline rows={4} placeholder="Type your message, min. 150 characters (required)" {...register("message", {required: {value: true, message: "Message is required!"}, minLength: {value: 150, message: "Min. length is 150 characters!"}})} /></div>
        {/*Submit*/}
        <div>
          <Box sx={{ '& > button': { m: 1 } }}>
            {loading === true ? <LoadingButton class='darkmode-ignore' variant="contained" loading={true} disabled={true} loadingPosition='start'><span style={{fontWeight: 'bolder'}}>Sending...</span></LoadingButton> : <Button variant="contained" type="submit" endIcon={<SendIcon />}>Send</Button>}
          </Box>
          <p>{notif}</p>
        </div>
      </Box>
    </div>
  );
}

export default Contact
