import React, { useState, useEffect } from 'react'
import { TailSpin } from 'react-loading-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
const axios = require('axios')
const request = require('../../../config/request')

function RightUpper(){
  const [loadWorkExp, setLoadWorkExp] = useState(true)
  const [workExp, setWorkExp] = useState([])
  const id = 'right_upper'
  useEffect(() => {
    (async() => {
      let arrWorkExp = {data: []}
      if(loadWorkExp){
        //Work Experience
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'work'}).then(
          response => {
            console.log(response.data.data)
            arrWorkExp.data.push(response.data.data)
          }
        )
        setWorkExp([...arrWorkExp.data[0]])
        setLoadWorkExp(false)
      }
      console.log('workExp')
      console.log(workExp)
    })()
  }, [loadWorkExp, setLoadWorkExp, workExp, setWorkExp])
  const right_upper = {
    id: id,
    className: 'w3-twothird',
    w3containerw3cardw3whitew3marginbottom: {
      className: 'w3-container w3-card w3-white w3-margin-bottom',
      w3textgreyw3padding16: {
        className: 'w3-text-grey w3-padding-16'
      },
      w3container: {
        className: 'w3-container',
        w3opacity: {
          className: 'w3-opacity'
        },
        w3textteal: {
          className: 'w3-text-teal'
        }
      }
    }
  }
  if(loadWorkExp){
    return(<TailSpin stroke="#264b87" direction='ltr' />)
  }
  return(
    <div id={right_upper.id} className={right_upper.w3containerw3cardw3whitew3marginbottom.className}>
      <h2 className={right_upper.w3containerw3cardw3whitew3marginbottom.w3textgreyw3padding16.className}>
        <FontAwesomeIcon icon={faSuitcase} /> Work Experience
      </h2>
      {/*Start looping work experiences here*/}
      {workExp.length > 0 ? Object.keys(workExp).map(key =>
        <div key={'work_exp_' + key}>
          <h5 className= {right_upper.w3containerw3cardw3whitew3marginbottom.w3container.w3opacity.className}>{workExp[key].position} at <strong>{workExp[key].company}</strong></h5>
          <h6 className={right_upper.w3containerw3cardw3whitew3marginbottom.w3container.w3textteal.className}>
          {workExp[key].period}
          </h6>
          <p dangerouslySetInnerHTML={{__html: workExp[key].description.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}></p>
          <hr/>
        </div>
      ) : <></>}
      {/*End looping work experiences here*/}
    </div>
  )
}

export default RightUpper
