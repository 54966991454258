import React from 'react'
import RightUpper from './right_upper/RightUpper'
import RightBottom from './right_bottom/RightBottom'

function Right(){
  const id = 'right'
  const right = {
    id: id,
    w3twothird: {
      className: 'w3-twothird',
      align: 'left'
    }
  }
  return(
    <div className={right.w3twothird.className} align={right.w3twothird.align}>
      <RightUpper></RightUpper>
      <RightBottom></RightBottom>
    </div>
  )
}

export default Right
