import React, { useState, useEffect } from 'react'
import { TailSpin } from 'react-loading-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
const axios = require('axios')
const request = require('../../../config/request')

function RightBottom(){
  const [loadEducations, setLoadEducations] = useState(true)
  const [educations, setEducations] = useState([])
  const id = 'right_bottom'
  useEffect(() => {
    (async() => {
      let arrEducations = {data: []}
      if(loadEducations){
        //Work Experience
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'education'}).then(
          response => {
            console.log(response.data.data)
            arrEducations.data.push(response.data.data)
          })
        setEducations([...arrEducations.data[0]])
        setLoadEducations(false)
      }
      console.log('educations')
      console.log(educations)
    })()
  }, [loadEducations, setLoadEducations, educations, setEducations])
  const right_bottom = {
    id: id,
    w3containerw3cardw3white: {
      className: 'w3-container w3-card w3-white',
      w3textgreyw3padding16: {
        className: 'w3-text-grey w3-padding-16'
      },
      w3container: {
        className: 'w3-container',
        w3opacity: {
          className: 'w3-opacity'
        },
        w3textteal: {
          className: 'w3-text-teal'
        }
      }
    }
  }
  if(loadEducations){
    return(<TailSpin stroke="#264b87" direction='ltr' />)
  }
  return(
    <div id={right_bottom.id} className={right_bottom.w3containerw3cardw3white.className}>
      <h2 className={right_bottom.w3containerw3cardw3white.w3textgreyw3padding16.className}><FontAwesomeIcon icon={faCertificate} /> Education</h2>
      {/*Start looping educations here*/}
      {educations.length > 0 ? Object.keys(educations).map(key =>
        <div key={'education_' + key} className={right_bottom.w3containerw3cardw3white.w3container.className}>
          <h5 className={right_bottom.w3containerw3cardw3white.w3container.w3opacity.className}>
            <b>{educations[key].institution}</b>
          </h5>
          <h6 className={right_bottom.w3containerw3cardw3white.w3container.w3textteal.className}>
            <FontAwesomeIcon icon={faCalendar} /> {educations[key].period}
          </h6>
          <p>{educations[key].description}</p>
          <hr/>
        </div>
      ) : <></>}
      {/*End looping educations here*/}
    </div>
  )
}

export default RightBottom
