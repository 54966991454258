import React, { useState, useEffect } from 'react'
import useAnalyticsEventTracker from '../../GoogleAnalytics'
import { TailSpin } from 'react-loading-icons'
import Typewriter from 'typewriter-effect'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons'
import { /*faPhoneSquare, faEnvelope, */faAsterisk, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faChrome } from '@fortawesome/free-brands-svg-icons'
const axios = require('axios')
const request = require('../../config/request')
const googleAnalytics = ((post) => {
  useAnalyticsEventTracker(post);
})

function Left(){
  const [loadBio, setLoadBio] = useState(true)
  const [biodata, setBiodata] = useState([])
  const [skills, setSkills] = useState([])
  const [languages, setLanguages] = useState([])
  const id = 'left'
  useEffect(() => {
    (async() => {
      let arrBio = {data: []}, arrSkills = {data: []}, arrLanguage = {data: []}
      if(loadBio){
        //Bio
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'bio'}).then(
          response => {
            arrBio.data.push(response.data.data)
          }
        )
        //Skills
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'skills'}).then(
          response => {
            arrSkills.data.push(response.data.data)
          }
        )
        //Languages
        await axios.post(request.api.url + '/v1/cv/cv-json', {section: 'language'}).then(
          response => {
            arrLanguage.data.push(response.data.data)
          }
        )
        setBiodata([...arrBio.data[0]])
        setSkills([...arrSkills.data[0]])
        setLanguages([...arrLanguage.data[0]])
        setLoadBio(false)
      }
      console.log('biodata')
      console.log(biodata)
      console.log('skills')
      console.log(skills)
      console.log('languages')
      console.log(languages)
    })()
  }, [loadBio, setLoadBio, biodata, setBiodata, skills, setSkills, languages, setLanguages])
  const left = {
    id: id,
    w3third: {
      className: 'w3-third',
      w3white: {
        className: 'w3-white w3-text-grey w3-card-4',
        ww3displaycontainer: {
          className: 'w3-display-container"',
          img: {
            style: {width: '100%'},
            alt: 'Avatar'
          },
          w3displaybottomleft: {
            className: 'w3-display-bottomleft w3-container w3-text-black'
          }
        },
        w3container: {
          className: 'w3-container',
          w3large: {
            className: 'w3-large'
          },
          w3lightgrey: {
            className: 'w3-light-grey w3-round-xlarge w3-small',
            w3container: {
              className: 'w3-container w3-center w3-round-xlarge w3-teal',
              w3center: {
                className: 'w3-center w3-text-white'
              }
            }
          },
          w3largew3texttheme: {
            className: 'w3-large w3-text-theme'
          },
          w3lightgreyw3roundxlarge: {
            className: 'w3-light-grey w3-round-xlarge',
            w3roundxlarge: {
              className: 'w3-round-xlarge w3-teal',
              style: {width: '100%', height: '24px'}
            }
          }
        }
      }
    }
  }
  if(loadBio){
    return(<TailSpin stroke="#264b87" direction='ltr' />)
  }
  return (
    <div id={left.id} className={left.w3third.className}>
      <div className={left.w3third.w3white.className}>
        <div className={left.w3third.w3white.ww3displaycontainer.className}>
          <img style={left.w3third.w3white.ww3displaycontainer.img.style} alt={left.w3third.w3white.ww3displaycontainer.img.alt} src={biodata.length > 0 ? biodata[0].value : ''} />
          {/*<div className={left.w3third.w3white.ww3displaycontainer.w3displaybottomleft.className}>
            <h2>{biodata.length > 0 ? biodata[1].value : ''}</h2>
          </div>*/}
        </div>
        <div className={left.w3third.w3white.w3container.className}>
          <h2>
            <Typewriter options={{
              strings: [biodata.length > 0 ? biodata[1].value : ''],
              autoStart: true,
              loop: true
            }} />
          </h2>
          <p><strong><FontAwesomeIcon icon={faCalendarDays} /> Birth Place & Date:</strong><br/>{biodata.length > 0 ? biodata[2].value + ', ' + biodata[3].value : ''}</p>
          {/*<p><strong><FontAwesomeIcon icon={faPhoneSquare} /> Phone Number:</strong><br/>{biodata.length > 0 ? biodata[8].value : ''}</p>*/}
          {/*<p><strong><FontAwesomeIcon icon={faEnvelope} /> Email:</strong><br/>
            <Link href={biodata.length > 0 ? 'mailto:' + biodata[9].value : ''} target='_blank' rel='noreferrer' onClick={() => {
              let post = {
                category: 'link',
                action: 'click_email',
                label: 'email'

              }
              console.log(post)
              googleAnalytics(post)
            }}>
              {biodata.length > 0 ? biodata[9].value : ''}
            </Link>
          </p>*/}
          <p><strong><FontAwesomeIcon icon={faChrome} /> Website:</strong><br/>
            <Link href={biodata.length > 0 ? biodata[10].value : ''} onClick={() => {
              let post = {
                category: 'link',
                action: 'click_website',
                label: 'website'

              }
              console.log(post)
              googleAnalytics(post)
            }}>
              {biodata.length > 0 ? biodata[10].value : ''}
            </Link>
          </p>
          <hr/>
          <p className={left.w3third.w3white.w3container.w3large.className}>
            <b><FontAwesomeIcon icon={faAsterisk} /> Skills</b><br/>
            <span style={{fontSize: 'smaller'}}>Click each skills to check the meter</span>
          </p>
          {/*Start looping skills*/}
          {skills.length > 0 ? Object.keys(skills).map(key =>
            <div key={'skill_' + key}>
              <p>
                <Button variant='text' sx={{textTransform: 'none', fontSize: 'larger'}} onClick={() => {
                  let progress = 0
                  setInterval(() => {
                    if(progress < parseInt(skills[key].score)){
                      console.log(progress)
                      progress = progress + 1
                      document.getElementById('skill_meter_' + key).style.width = progress + '%'
                      document.getElementById('skill_meter_label_' + key).innerHTML = progress
                    }
                  }, 10)
                  clearInterval()
                  let post = {
                    category: 'skill',
                    action: 'click_skill_' + skills[key].value,
                    label: 'skill_' + skills[key].value
                  }
                  console.log(post)
                  googleAnalytics(post)
                }}>{skills[key].value}</Button>
              </p>
              <div className={left.w3third.w3white.w3container.w3lightgrey.className}>
                <div id={'skill_meter_' + key} className={left.w3third.w3white.w3container.w3lightgrey.w3container.className} style={{width: '0%'}}>
                  <div className={left.w3third.w3white.w3container.w3lightgrey.w3container.w3center.className}><span id={'skill_meter_label_' + key}>{'0'}</span>{'%'}</div>
                </div>
              </div>
            </div>
          ) : <></>}
          {/*End looping skills*/}
          <br/>
          <p className={left.w3third.w3white.w3container.w3largew3texttheme.className}>
            <b><FontAwesomeIcon icon={faGlobe} /> Languages</b><br/>
            <span style={{fontSize: 'smaller'}}>Click each language to check the meter</span>
          </p>
          {/*Start looping languages*/}
          {languages.length > 0 ? Object.keys(languages).map(key =>
            <div key={'language_' + key}>
              <p>
                <Button variant='text' sx={{textTransform: 'none', fontSize: 'larger'}} onClick={() => {
                  let progress = 0
                  setInterval(() => {
                    if(progress < parseInt(languages[key].score)){
                      console.log(progress)
                      progress = progress + 1
                      document.getElementById('language_meter_' + key).style.width = progress + '%'
                      document.getElementById('language_meter_label_' + key).innerHTML = progress
                    }
                  }, 10)
                  clearInterval()
                  let post = {
                    category: 'language',
                    action: 'click_language_' + languages[key].value,
                    label: 'language_' + languages[key].value
                  }
                  console.log(post)
                  googleAnalytics(post)
                }}>{languages[key].value}</Button>
              </p>
              <div className={left.w3third.w3white.w3container.w3lightgreyw3roundxlarge.className}>
                <div id={'language_meter_' + key} className={left.w3third.w3white.w3container.w3lightgreyw3roundxlarge.w3roundxlarge.className}
                style={{height: '24px', width: '0%'}}>
                  <span id={'language_meter_label_' + key}>{'0'}</span>{'%'}
                </div>
              </div>
            </div>
          ) : <></>}
          {/*End looping languages*/}
          <br/>
        </div>
      </div>
      <br/>
    </div>
  )
}

export default Left
