import React, { useState, useRef } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import './App.css'
import ReactGA4 from 'react-ga4'
import Darkmode from 'darkmode-js'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import PhoneIcon from '@mui/icons-material/Phone'
import Paper from '@mui/material/Paper'
import Home from './component/Home'
import Contact from './component/Contact'
import Footer from './component/footer/Footer'
ReactGA4.initialize([
  {
    trackingId: (process.env.NODE_ENV === 'production' ? 'G-7HTN69HP2W' : 'G-ZC6V34CWH9'),
    debug: (process.env.NODE_ENV === 'production' ? false : true),
    gtagOptions: {
      js: new Date(),
      config: (process.env.NODE_ENV === 'production' ? 'G-7HTN69HP2W' : 'G-ZC6V34CWH9')
    }
  }
])

function Nav() {
  const [value, setValue] = useState('/')
  const ref = useRef(null)
  const pathname = window.location.pathname
  console.log(pathname)
  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, opacity: 0.90 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={pathname}
          onChange={(event, newValue) => {
            setValue(pathname);
            console.log(value)
          }}
        >
          <BottomNavigationAction value='/' component={Link} to='/' label='HOME' icon={<HomeIcon />} onClick={() => {
            ReactGA4.event({
              category: 'route',
              action: 'click_' + pathname.replace('/', ''),
              label: pathname.replace('/', '')
            })
          }} />
          <BottomNavigationAction value='/contact' component={Link} to='/contact' label='KEEP IN TOUCH' icon={<PhoneIcon />} onClick={() => {
            ReactGA4.event({
              category: 'route',
              action: 'click_' + pathname.replace('/', ''),
              label: pathname.replace('/', '')
            })
          }} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}

function App() {
  const options = {
    bottom: '64px', // default: '32px'
    right: 'unset', // default: '32px'
    left: '32px', // default: 'unset'
    time: '0.5s', // default: '0.3s'
    mixColor: '#fff', // default: '#fff'
    backgroundColor: '#fff',  // default: '#fff'
    buttonColorDark: '#100f2c',  // default: '#100f2c'
    buttonColorLight: '#fff', // default: '#fff'
    saveInCookies: true, // default: true,
    label: '🌓', // default: ''
    autoMatchOsTheme: true // default: true
  }
  const darkmode = new Darkmode(options)
  darkmode.showWidget()
  ReactGA4.send({ hitType: 'pageview', page: '/', title: 'CV of Antonius L'});
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/contact' element={<ContactPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

function HomePage() {
  return (
    <div className='homepage'>
      <Home></Home>
      <Nav></Nav>
      <Footer></Footer>
    </div>
  );
}

function ContactPage() {
  return (
    <div className='contactpage'>
      <Contact></Contact>
      <Nav></Nav>
      <Footer></Footer>
    </div>
  );
}

export default App;
