import React from 'react'
import Left from './left/Left'
import Right from './right/Right'

function Home() {
  return (
    <div className="w3-content w3-margin-top" style={{maxWidth: '1400px'}}>
      <div className="w3-row-padding">
        <Left></Left>
        <Right></Right>
      </div>
    </div>
  );
}

export default Home;
