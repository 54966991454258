import ReactGA4 from 'react-ga4'

const useAnalyticsEventTracker = (post) => {
    ReactGA4.event({
      category: post.category,
      action: post.action,
      label: post.label
    })
};

export default useAnalyticsEventTracker;
